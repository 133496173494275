<script setup>
</script>
<template>
      <ul class="navbar-nav justify-content-end" >
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'registration'}">Registration</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'login'}">Login</router-link>
        </li>
      </ul>
</template>