<script setup>
import { useAuthStore } from '@/store/auth.store';


 const authStore = useAuthStore(); 

</script>
<template>
      <ul class="navbar-nav justify-content-end" >
          <li class="nav-item dropdown">
          <router-link class="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person-square"></i> Profile
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link class="nav-item nav-link" @click="authStore.logout()" to="#">Logout</router-link>
        </li>
      </ul>
</template>