<template>
  <div class="container-fluid">
    <NavBar />
    <router-view/>
  </div>
</template>

<script>
import NavBar from '@/components/Menu/NavBar.vue'

export default {
  components: {
    NavBar
  }
}
</script>

<style>
</style>
