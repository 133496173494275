<script setup>
import { onMounted, ref } from 'vue';
import { RouterLink } from 'vue-router';
import GuestMenu from '@/components/Menu/GuestMenu.vue';
import UserMenu from './UserMenu.vue';
import axios from 'axios';
import { useAuthStore } from '@/store/auth.store';


const authStore = useAuthStore();
const brands = ref();

onMounted( () =>{
  axios.get('/api/nb')
    .then(response=> brands.value = response.data.brands.items)
  })
</script>

<template>
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <router-link class="navbar-brand" :to="{name: 'home'}">T4A.su<br><span class="fs-6">Техника для всех</span></router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'about'}">About</router-link>
        </li>
        <li class="nav-item dropdown">
          <router-link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Ноутбуки
          </router-link>
          <ul class="dropdown-menu">
            <li v-for="brand in brands" :key="brand.id"><router-link class="dropdown-item" :to="{name: 'brand', params:{ brand: brand.slug} }">{{brand.title}}</router-link></li>
            <li><hr class="dropdown-divider"></li>
            <li><router-link class="dropdown-item" :to="{name: 'brands'}">Показать все</router-link></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'about'}" >ПЛИС</router-link>
        </li>
        <li class="nav-item dropdown">
          <router-link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Микроконтроллеры
          </router-link>
          <ul class="dropdown-menu">
            <!-- <li v-for="brand in brands" :key="brand.id"><router-link class="dropdown-item" :to="{name: 'brand', params:{ brand: brand.slug} }">{{brand.title}}</router-link></li> -->
            <li><router-link to="#">Микрон</router-link></li>
            <li><router-link to="#">stm32</router-link></li>
            <li><router-link to="#">ATMEL</router-link></li>
            <li><router-link to="#">CH32</router-link></li>
            <li><hr class="dropdown-divider"></li>
            <li><router-link class="dropdown-item" :to="{name: 'brands'}">Показать все</router-link></li>
          </ul>
        </li>
      </ul>

      
      <GuestMenu v-if="!authStore.logedIn"/>
      <UserMenu v-else/>
      
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Поиск по модели" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
      
    </div>
  </div>
</nav>
</template>

<style>

</style>