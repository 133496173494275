import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () =>import('../views/LoginView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () =>import('../views/RegistrationView.vue')
  },
  {
    path: '/nb/:brand',
    name: 'brand',
    component: () =>import('../views/BrandView.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    component: () =>import('../views/BrandsView.vue')
  },
  {
    path: '/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/nb/:brand/:model',
    name: 'notebook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NoteBookView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
