import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import { computed, ref } from "vue";

export const useAuthStore = defineStore('auth' , ()=>{
    const token = ref()

    const logedIn = computed(() => {
        return token.value !==null ? true : false;
    })

    async function login(email, password) { 
        await axios.post('/api/login_check',{'email':email, 'password':password},{'headers':{'Content-Type':'application/json','Accept':'applicaton/json'}})
            .then((response) =>{ localStorage.setItem('token',response.data.token)});
            this.token = localStorage.getItem('token');
            router.push('/');
    }

    function logout() {
        localStorage.removeItem('token');
        this.token = null;
        router.push('/login');
    }

    return { token, login, logout, logedIn }
});